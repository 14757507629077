import _ from 'lodash';
import { z } from 'zod';
import { DateTime } from 'luxon';
import {
   TransformArrayOfNumbers,
   TransformArrayOfStrings,
} from '../utils/transformers';
import { stateAbbreviation, stateTitles } from '@innerwell/us-states';

export const booleanExtendedField = z
   .string()
   .or(z.number())
   .or(z.boolean())
   .transform((val) => {
      if (_.isString(val)) {
         return ['true', '1'].includes(val.toLowerCase());
      }
      if (_.isNumber(val)) {
         return Boolean(val);
      }

      return val;
   });

// TODO: when string like 3.55 is passed, it just converts it to 3 without error
export const intExtendedField = z.union([
   // Just ignore empty string. It might be passed by frontend when there's no parameter in GET request,
   // like page=&size=&sort=
   // With useZodForm this doesn't play nicely, it results in {status: 'aborted'} being assigned as a field value.
   z.literal('').transform(() => {
      return z.NEVER;
   }),
   z
      .string()
      .transform((str) => parseInt(str, 10))
      .refine((n) => !_.isNaN(n), {
         message: 'Invalid number.',
      }),
   z.number(),
]);

export const dateTimeField = z.string().transform((val, ctx) => {
   try {
      return DateTime.fromISO(val, { zone: 'utc' });
   } catch (e) {
      ctx.addIssue({
         code: 'invalid_date',
         message: `Invalid ISO date: ${val}`,
      });
      return z.NEVER;
   }
});

// Requires format like 3.55
export const stringAmount = z
   .string()
   .regex(/^\d+\.\d\d$/, 'Amount must be in format x.xx, e.g. 242.00');

export const dateField = z.string().transform((val, ctx) => {
   try {
      const d = DateTime.fromISO(val, { zone: 'utc' });
      if (
         d.hour === 0 &&
         d.minute === 0 &&
         d.second === 0 &&
         d.millisecond === 0
      ) {
         return d;
      }

      ctx.addIssue({
         code: 'invalid_date',
         message: `Date has time part: ${val}`,
      });
      return z.NEVER;
   } catch (e) {
      ctx.addIssue({
         code: 'invalid_date',
         message: `Invalid ISO date: ${val}.${_.isObject(e) && e && 'message' in e ? ` [${e.message}]` : ''}`,
      });
      return z.NEVER;
   }
});

export const arrayOfNumbersField = z
   .union([z.string(), z.array(z.union([z.string(), z.number()]))])
   .transform(TransformArrayOfNumbers)
   .pipe(z.array(z.number()));

export const arrayOfStringsField = z
   .union([z.string(), z.array(z.string())])
   .transform(TransformArrayOfStrings);
//.pipe(z.array(z.string()));

export const uuidSchema = z.string().uuid();

const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
type Literal = z.infer<typeof literalSchema>;
export type Json = Literal | { [key: string]: Json } | Json[];

export const jsonSchema: z.ZodType<Json> = z.lazy(() =>
   z.union([
      z.string(),
      z.number(),
      z.boolean(),
      z.null(),
      z.array(jsonSchema),
      z.record(jsonSchema),
   ]),
);
export const jsonObjectSchema = z.record(jsonSchema);

export const phoneField = z
   .string()
   .min(1)
   .refine(
      (value: string) => {
         // format:
         // +1 NXX-NXX-XXXX
         // N=digits 2–9, X=digits 0–9
         const US_PHONE_NUMBER_REGEX = /\+1[2-9]\d{2}[2-9]\d{6}$/;
         return US_PHONE_NUMBER_REGEX.test(value);
      },
      { message: 'Invalid phone number' },
   );

// This is a phone number in format 1234567890, so no hyphens at all
export const phoneFieldNoCountryCodeOnlyDigits = z.string().length(10);

export const stateField = z.enum(stateTitles as [string, ...string[]]);
export const stateAbbrField = z.enum(stateAbbreviation);

export const dateTimeISO8601Field = z.string().refine(
   (value) => {
      try {
         return DateTime.fromISO(value, { zone: 'utc' }).isValid;
      } catch (e) {
         return false;
      }
   },
   { message: 'date field must be in ISO 8601 format' },
);

export const passwordRegex =
   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=])(?=.*\d)[A-Za-z\d^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]{8,}$/;
export const passwordField = z
   .string()
   .min(1, { message: 'Password is required' })
   .regex(
      passwordRegex,
      `Password must have 8 or more characters with a mix of uppercase and lowercase letters, numbers and symbols (^$*.[]{}()?-"!@#%&/\\,><':;|_~\`+=).`,
   );

export const offsetPaginationSchema = z.object({
   skip: z.number({ coerce: true }).int().min(0).optional(),
   take: z.number({ coerce: true }).int().min(1).optional(),
});

export const zipCodeField = z
   .string()
   .min(1, 'ZIP Code is required.')
   .regex(/^[0-9]+$/, 'Must be only digits.')
   .min(5, 'Must be exactly 5 digits.')
   .max(5, 'Must be exactly 5 digits.');

export const slugField = z
   .string()
   .regex(/^[a-z0-9-]+$/, {
      message:
         'Slug must be lowercase and can only contain letters, numbers, and hyphens',
   })
   .optional()
   .or(z.literal(''));
