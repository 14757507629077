import { optional, z } from 'zod';

export const createClinicianSchema = z.object({
   firstName: z.string().min(1, { message: 'First name is required' }),
   lastName: z.string().min(1, { message: 'Last name is required' }),
   timetapId: z.preprocess(
      (x) => Number(x),
      z.number().min(1, { message: 'Timetap ID is required' }),
   ),
   welkinId: z
      .string()
      .min(1, { message: 'Welkin ID is required' })
      .uuid({ message: 'Welkin ID must be a valid UUID' }),
   // About: https://www.cms.gov/medicare/regulations-guidance/administrative-simplification
   // Validation & check digit: https://www.cms.gov/Regulations-and-Guidance/Administrative-Simplification/NationalProvIdentStand/Downloads/NPIcheckdigit.pdf
   npi: z.string().length(10).nullable().optional(),
   drFirstName: z.string().nullable().optional(),
   pinToTop: z.boolean().optional(),
   treatmentMethodIds: z
      .array(
         z
            .string()
            .uuid({ message: 'Treatment method ID must be a valid UUID' }),
      )
      .optional(),
   serviceIds: z
      .array(z.string().uuid({ message: 'Service ID must be a valid UUID' }))
      .optional(),
});

export type CreateClinicianDto = z.infer<typeof createClinicianSchema>;
